
interface SelectItem {
  itemNameEn: string;
  itemCode: string;
}

interface FileListType {
  id: string;
  fileName: string;
}

interface SearchDataType {
  dealerCode: null;
  dealerNameCn: null;
  dealerNameEn: null;
  region: null;
  vinNos: Array<string>;
  checkResult: Array<string>;
  checkingStatus: Array<string>;
  modalYear: Array<string>;
  modal: null;
  appeal: null;
  vinNo: string | null;
  claimId: string | null;
  claimType: string | null;
  dealerSwtCode: string | null;
  holdingDateFrom: Moment | null;
  holdingDateTo: Moment | null;
  communicationTimes: null;
  queryDealerTrade: null;
}

interface TableType {
  checkResult: string;
  status: string;
  comments: string;
  dealerCode: string;
  dealerNameCn: string;
  dealerNameEn: string;
  finalBonus: string;
  id: string;
  retailEligibilityStatus: string;
  isAppeal: string;
  isSubmitted: string;
  model: string;
  modelYear: string;
  offerTypeId: string;
  offerTypeName: string;
  progDesignId: string;
  programCode: string;
  programName: string;
  programNameCn: string;
  region: string;
  spotCheckingComments: string;
  vinNo: string;
  docMatchingResult: string;
  isManualMatching: string;
  dealerSwtCode: string;
}

import { message, Modal } from 'ant-design-vue';
import { ref, onMounted, nextTick, reactive, toRefs, defineComponent } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import {
  exportPageApiSIPO,
  checkSignCommunicationConfirmApi,
  getModelYearApi,
  getGroupNum,
  getRegionApi,
  getProgramInfoApi,
  rejectSIPO,
  rejectSIPOConfirm,
  setDealerCommunicate,
  confirmSIPO,
  confirmSIPOConfirm,
  getSIPOCheckingDetailList,
  getFindByGroupCode,
  getCheckingState,
  getSourceData,
  deleteSourceDataApi,
  downloadSourceDataApi,
  findRoundAndBatch,
  getClaimType as getClaimTypeApi
} from '@/API/checking/SICCOCheckingDetail';
import { useRoute } from 'vue-router';
import Detail from './Detail/index.vue';
import moment, { Moment } from 'moment';

interface RegionType {
  id: string;
  subRegionEn: string;
}

enum Status {
  Exemption = "99"
}

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    if (from.fullPath === '/checking/SIPOChecking') {
      next();
    } else {
      next('/checking/SIPOChecking');
    }
  },
  components: {
    Detail
  },
  setup() {
    const route = useRoute();

    const approved = ref('0');
    const rejected = ref('0');
    const pending = ref('0');
    const programId = ref(route.params.programId);
    const offerTypeId = ref(route.params.programCategoryId === 'null' ? '' : route.params.programCategoryId);
    const programCode = ref(route.params.programCode === 'null' ? '' : route.params.programCode);
    const programName = ref(route.params.programName);
    const programCategory = ref(route.params.programCategory);
    const checkingPlan = ref(route.params.checkingPlan);
    const bu = ref(route.params.buName === 'null' ? '' : route.params.buName);
    const offerTypePathKey = ref(route.params.offerTypePathKey === 'null' ? '' : route.params.offerTypePathKey);

    if (checkingPlan.value === null || checkingPlan.value === undefined || checkingPlan.value === '') {
      const param = {
        programId: programId.value,
        offerTypeId: offerTypeId.value
      };
      findRoundAndBatch(param).then((res) => {
        checkingPlan.value = res;
      });
    }

    const operationParams = ref<any>({});
    const operationType = ref('');

    const deatilModal = ref<any>(null);
    const modelYearArray = ref<string[]>([]);

    //region的数组
    const regionArray = ref<RegionType[]>([]);
    //claimType数组
    const claimTypeArray = ref<{ key: string; name: string }[]>([]);

    //DealerCommunication数据
    const dealerCommunicationObj = reactive({
      dealerCommunicationVisible: false,
      deadline: '',
      roundClosing: 'Y',
      lastCommunication: 'Y',
      commentValue: '',
      isDeadlineEmpty: false
    });

    const communicationClose = () => {
      dealerCommunicationObj.dealerCommunicationVisible = false;
      dealerCommunicationObj.deadline = '';
      dealerCommunicationObj.roundClosing = 'Y';
      dealerCommunicationObj.lastCommunication = 'Y';
      dealerCommunicationObj.commentValue = '';
      dealerCommunicationObj.isDeadlineEmpty = false;
    };

    //筛选内容
    const searchData = reactive<SearchDataType>({
      dealerCode: null,
      dealerNameCn: null,
      dealerNameEn: null,
      region: null,
      vinNos: [''],
      checkResult: [],
      checkingStatus: [],
      modalYear: [],
      modal: null,
      appeal: null,
      vinNo: '',
      claimId: null,
      claimType: null,
      dealerSwtCode: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      communicationTimes: null,
      queryDealerTrade: null
    });

    const searchDataTemporary = reactive<SearchDataType>({
      dealerCode: null,
      dealerNameCn: null,
      dealerNameEn: null,
      region: null,
      vinNos: [''],
      checkResult: [],
      checkingStatus: [],
      modalYear: [],
      modal: null,
      appeal: null,
      vinNo: '',
      claimId: null,
      claimType: null,
      dealerSwtCode: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      communicationTimes: null,
      queryDealerTrade: null
    });

    const holdingPeriodChange = (val: Moment[]) => {
      searchData.holdingDateFrom = val[0];
      searchData.holdingDateTo = val[1];
    };

    // 批量查询
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });
    //Modal的输入框
    const textContentNo = ref<string>('');
    //输入框，用于控制焦点
    const input = ref();
    //显示的VIN数组
    const textNoArray = ref<string[]>([]);
    //空占位
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    const cleanEmptyEleArray = (array: Array<string>) => {
      if (array.length > 1) {
        const tempArray = array.filter((item) => item !== '');
        tempArray.length || tempArray.push('');
        return tempArray;
      } else {
        return array;
      }
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = 'VIN No. Query';
      batchQuery.batchQueryListTitle = 'VIN No. List';
      textNoArray.value = cleanEmptyEleArray(searchData.vinNos)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinNos);
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('Please paste into the text box from excel');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g, ',').split(','));
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      searchData.vinNos = [];
      searchData.vinNos = searchData.vinNos.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchData.vinNo = cleanEmptyEleArray(searchData.vinNos)[0] as string;
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchData.vinNos = [''];
      textNoArray.value = cleanEmptyEleArray(searchData.vinNos)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinNos);
      setBatchPlaceholder();
      searchData.vinNo = null;
      input.value.focus();
    };

    //悬浮判断的id
    const hoverFileId = ref('-1');

    //Source Data文件列表
    const fileList = ref<FileListType[]>([]);

    const tableHeight = window.innerHeight - 590;

    const dataSource = ref<TableType[]>([]);

    const columns = reactive([
      {
        title: 'Claim ID',
        dataIndex: 'claimId',
        key: 'claimId',
        width: 100
      },
      {
        title: 'Claim Type',
        dataIndex: 'claimType',
        key: 'claimType',
        width: 100
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: 100
      },
      {
        title: 'Dealer Name',
        dataIndex: 'dealerNameCn',
        key: 'dealerNameCn',
        width: 280
      },
      {
        title: 'Dealer Code',
        dataIndex: 'dealerCode',
        key: 'dealerCode',
        width: 120
      },
      {
        title: 'SWT Code',
        dataIndex: 'dealerSwtCode',
        key: 'dealerSwtCode',
        width: 180
      },
      {
        title: 'VIN',
        dataIndex: 'vinNo',
        key: 'vinNo',
        width: 190
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: 210
      },
      {
        title: 'Model Year',
        dataIndex: 'modelYear',
        key: 'modelYear',
        width: 120
      },
      {
        title: 'Retail Eligiblity Status',
        dataIndex: 'retailEligibilityStatus',
        key: 'retailEligibilityStatus',
        width: 220
      },
      {
        title: 'Dealer Trade',
        dataIndex: 'dealerTrade',
        key: 'dealerTrade',
        width: 120
      },
      {
        title: 'WS Dealer Code',
        dataIndex: 'dealerTradeWholeSaleCode',
        key: 'dealerTradeWholeSaleCode',
        width: 160
      },
      {
        title: 'WS Dealer Name',
        dataIndex: 'dealerTradeWholeSaleNameCn',
        key: 'dealerTradeWholeSaleNameCn',
        width: 260
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 180
      },
      {
        title: 'Check Result',
        dataIndex: 'checkResult',
        key: 'checkResult',
        width: 120
      },
      {
        title: 'Final Bonus',
        dataIndex: 'finalBonus',
        key: 'finalBonus',
        width: 120
      },
      {
        title: 'Appeal',
        dataIndex: 'isAppeal',
        key: 'isAppeal',
        width: 120
      },
      {
        title: 'Holding Period DDL',
        dataIndex: 'retirementDate',
        key: 'holdingDate',
        width: 180
      },
      {
        title: 'Communication Times',
        dataIndex: 'communicationTimes',
        key: 'communicationTimes',
        width: 180
      },
      {
        title: 'Operation',
        key: 'action',
        slots: { customRender: 'action' },
        fixed: 'right',
        width: 120
      }
    ]);

    type Key = ColumnProps['key'];
    const state = reactive<{
      selectedRowKeys: Key[];
      loading: boolean;
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false
    });

    const onSelectChange = (selectedRowKeys: Key[]) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 20
    });

    //清除筛选条件
    const reset = () => {
      searchDataTemporary.dealerCode = null;
      searchDataTemporary.dealerNameEn = null;
      searchDataTemporary.dealerNameCn = null;
      searchDataTemporary.region = null;
      searchDataTemporary.vinNos = [''];
      searchDataTemporary.checkResult = [];
      searchDataTemporary.checkingStatus = [];
      searchDataTemporary.modalYear = [];
      searchDataTemporary.vinNo = null;
      searchDataTemporary.modal = null;
      searchDataTemporary.appeal = null;
      searchDataTemporary.claimId = null;
      searchDataTemporary.claimType = null;
      searchDataTemporary.dealerSwtCode = null;
      searchDataTemporary.holdingDateFrom = null;
      searchDataTemporary.holdingDateTo = null;
      searchDataTemporary.communicationTimes = null;
      searchDataTemporary.queryDealerTrade = null;


      searchData.dealerCode = null;
      searchData.dealerNameEn = null;
      searchData.dealerNameCn = null;
      searchData.region = null;
      searchData.vinNos = [''];
      searchData.checkResult = [];
      searchData.checkingStatus = [];
      searchData.modalYear = [];
      searchData.modal = null;
      searchData.appeal = null;
      searchData.vinNo = null;
      searchData.claimId = null;
      searchData.claimType = null;
      searchData.dealerSwtCode = null;
      searchData.holdingDateFrom = null;
      searchData.holdingDateTo = null;
      searchData.communicationTimes = null;
      searchData.queryDealerTrade = null;
    };

    const getVINNo = (array: Array<Key>) => {
      const ids = [];
      for (let i = 0; i < array.length; i++) {
        const id = array[i];
        for (let j = 0; j < dataSource.value.length; j++) {
          if (id === dataSource.value[j].id) {
            ids.push(dataSource.value[j].vinNo);
          }
        }
      }

      return ids;
    };

    const getParams = (hasPage: boolean) => {
      const params: any = {
        offerTypeKey: offerTypePathKey.value,
        programBu: bu.value,
        checkResult: searchDataTemporary.checkResult,
        checkingStatus: searchDataTemporary.checkingStatus,
        dealerCode: searchDataTemporary.dealerCode,
        dealerNameCn: searchDataTemporary.dealerNameCn,
        dealerNameEn: searchDataTemporary.dealerNameEn,
        offerTypeId: offerTypeId.value,
        programCode: programCode.value,
        programId: programId.value,
        region: searchDataTemporary.region,
        modelYears: searchDataTemporary.modalYear,
        model: searchDataTemporary.modal,
        appeal: searchDataTemporary.appeal,
        vinNos:
          searchDataTemporary.vinNos.length === 1 && searchDataTemporary.vinNos[0] === ''
            ? []
            : searchDataTemporary.vinNos,
        vinNo:
          searchDataTemporary.vinNos.length >= 1 && searchDataTemporary.vinNos[0] !== ''
            ? ''
            : searchDataTemporary.vinNo,
        claimId: searchDataTemporary.claimId ?? null,
        claimType: searchDataTemporary.claimType ?? null,
        dealerSwtCode: searchDataTemporary.dealerSwtCode ?? null,
        holdingDateBegin: searchDataTemporary.holdingDateFrom,
        holdingDateEnd: searchDataTemporary.holdingDateTo,
        communicationTimes: searchDataTemporary.communicationTimes,
        queryDealerTrade: searchDataTemporary.queryDealerTrade
      };
      if (params.checkingStatus.includes(Status.Exemption)) {
        params.exemptionStatus = true
      }
      if (hasPage) {
        params.current = pagination.currentPage - 1;
        params.size = pagination.pageSize;
      }
      return params;
    };

    const sign = 0;
    //根据筛选条件查询表格
    const getTableDataByCondition = () => {
      //查询表格
      const params = getParams(true);
      getSIPOCheckingDetailList(params).then((res) => {
        const data = res.data;
        dataSource.value = data;

        state.selectedRowKeys = [];

        pagination.total = parseInt(res.totalNum);
      });
    };

    const searchTable = () => {
      searchDataTemporary.dealerCode = searchData.dealerCode;
      searchDataTemporary.dealerNameEn = searchData.dealerNameEn;
      searchDataTemporary.dealerNameCn = searchData.dealerNameCn;
      searchDataTemporary.region = searchData.region;
      searchDataTemporary.vinNos = searchData.vinNos;
      searchDataTemporary.vinNo = searchData.vinNo;
      searchDataTemporary.checkResult = searchData.checkResult;
      searchDataTemporary.checkingStatus = searchData.checkingStatus;
      searchDataTemporary.modalYear = searchData.modalYear;
      searchDataTemporary.modal = searchData.modal;
      searchDataTemporary.appeal = searchData.appeal;
      searchDataTemporary.claimId = searchData.claimId;
      searchDataTemporary.claimType = searchData.claimType;
      searchDataTemporary.dealerSwtCode = searchData.dealerSwtCode;
      searchDataTemporary.holdingDateFrom = searchData.holdingDateFrom;
      searchDataTemporary.holdingDateTo = searchData.holdingDateTo;
      searchDataTemporary.communicationTimes = searchData.communicationTimes;
      searchDataTemporary.queryDealerTrade = searchData.queryDealerTrade;
      pagination.currentPage = 1;
      getTableDataByCondition();
    };

    const getTableDataByClick = (params: any) => {
      getSIPOCheckingDetailList(params).then((res) => {
        const data = res.data;
        dataSource.value = data;
        state.selectedRowKeys = [];
        pagination.currentPage = 1;

        pagination.total = parseInt(res.totalNum);
      });
    };

    //获取source data列表
    const getSourceDataList = () => {
      getSourceData({
        programCode: programCode.value as string,
        offerTypeId: offerTypeId.value as string
      }).then((res) => {
        fileList.value = res;
      });
    };

    //删除SourceData
    const deleteSourceData = (id: string) => {
      Modal.confirm({
        title: 'Please confirm',
        content: 'Do you confirm to delete current file?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          deleteSourceDataApi({ sourceDataId: id }).then((res: any) => {
            message.success('Successfully deleted');
            getSourceDataList();
          });
        }
      });
    };

    //下载SourceData
    const downloadSourceData = (item: FileListType) => {
      downloadSourceDataApi({ sourceDataId: item.id }).then((res: string) => {
        const downloadElement = document.createElement('a');
        downloadElement.href = res;
        downloadElement.download = item.fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
      });
    };

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      state.selectedRowKeys = [];
      getTableDataByCondition();
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      state.selectedRowKeys = [];
      getTableDataByCondition();
    };

    const checkingResultArray = ref<SelectItem[]>([]);
    const checkingStatusArray = ref<SelectItem[]>([]);

    //获取Check Result，Status的下拉框参数
    const getSelect = () => {
      getFindByGroupCode({ groupCode: 'checking_result' }).then((res) => {
        checkingResultArray.value = [];
        checkingResultArray.value = res;
      });

      getCheckingState().then((res) => {
        const arr = [];
        for (let i = 0; i < res.length; i++) {
          arr.push({
            itemNameEn: res[i].name,
            itemCode: res[i].code
          });
        }
        checkingStatusArray.value = [];
        checkingStatusArray.value = arr;
      });
    };

    //概览的点击，查询表格
    const getTableByState = (type: string) => {
      let result = '';
      for (let i = 0; i < checkingResultArray.value.length; i++) {
        if (checkingResultArray.value[i].itemNameEn === type) {
          result = checkingResultArray.value[i].itemCode;
        }
      }

      const params = {
        checkResult: [result],
        current: 0,
        size: 20,
        offerTypeId: offerTypeId.value,
        offerTypeKey: offerTypePathKey.value,
        programBu: bu.value,
        programCode: programCode.value,
        programId: programId.value
      };
      getTableDataByClick(params);
    };

    //弹出框
    const confirmVisible = ref(false);
    const confirmTitle = ref('AAAA');

    const confirmDataSource = ref<any>();

    const confirmColumns = ref([
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no'
      },
      {
        title: 'Program Code',
        dataIndex: 'programCode',
        key: 'programCode'
      },
      {
        title: 'Program Category',
        dataIndex: 'programCategory',
        key: 'programCategory'
      },
      {
        title: 'Approved Qty',
        dataIndex: 'approveQty',
        key: 'approveQty'
      },
      {
        title: 'Rejected Qty',
        dataIndex: 'rejectedQty',
        key: 'rejectedQty'
      },
      {
        title: 'Pending Qty',
        dataIndex: 'pendingQty',
        key: 'pendingQty'
      },
      {
        title: 'Total',
        key: 'total',
        slots: { customRender: 'total' }
      }
    ]);

    const confirmClick = () => {
      if (operationType.value === 'confirm') {
        operationParams.value.confirm = 'Y';
        confirmSIPOConfirm(operationParams.value).then((res) => {
          if (res.code === '0') {
            message.success('Request submitted successfully. Please query result in task center');
            getTableDataByCondition();
          } else {
            message.error(res.message);
          }
          confirmVisible.value = false;
        });
      } else if (operationType.value === 'reject') {
        operationParams.value.confirm = 'Y';
        rejectSIPOConfirm(operationParams.value).then((res) => {
          if (res.code === '0') {
            message.success('Request submitted successfully. Please query result in task center');
            getTableDataByCondition();
          } else {
            message.error(res.message);
          }
          confirmVisible.value = false;
        });
      } else if (operationType.value === 'setDealer') {
        confirmVisible.value = false;
        dealerCommunicationObj.dealerCommunicationVisible = true;
      }
    };

    const customRow = (record: any) => {
      if (record.no === 'Total') {
        return { style: { 'font-weight': 700 } };
      }
    };

    const setDealerCommuncationPage = () => {
      let params = {};
      if (state.selectedRowKeys.length !== 0) {
        params = {
          ids: state.selectedRowKeys,
          offerTypeId: offerTypeId.value,
          programId: programId.value,
          confirm: 'N'
        };
      } else {
        const item = getParams(false);
        params = Object.assign(item, { confirm: 'N' });
      }
      operationParams.value = params;
      operationType.value = 'setDealer';

      setDealerCommunicate(params).then((res) => {
        if (res.code === '0') {
          //
          const table = [
            {
              no: '1',
              programCode: programCode.value,
              programCategory: programCategory.value,
              approveQty: 0,
              rejectedQty: 0,
              pendingQty: 0,
              total: 0
            },
            {
              no: 'Total',
              programCode: '',
              programCategory: '',
              approveQty: 0,
              rejectedQty: 0,
              pendingQty: 0,
              total: 0
            }
          ];
          const data = res.data;
          let sum = 0;
          confirmTitle.value = 'Set Dealer Communication';
          for (let i = 0; i < data.length; i++) {
            if (data[i].checkResult === 'Rejected') {
              table[0].rejectedQty = data[i].qty;
              table[1].rejectedQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Approved') {
              table[0].approveQty = data[i].qty;
              table[1].approveQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Pending') {
              table[0].pendingQty = data[i].qty;
              table[1].pendingQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            }
          }

          table[0].total = sum;
          table[1].total = sum;
          confirmDataSource.value = table;
          confirmVisible.value = true;
        } else {
          message.error(res.message);
        }
      });
    };

    const confirmPage = () => {
      let params = {};
      if (state.selectedRowKeys.length !== 0) {
        params = {
          ids: state.selectedRowKeys,
          offerTypeId: offerTypeId.value,
          programId: programId.value,
          confirm: 'N'
        };
      } else {
        const item = getParams(false);
        params = Object.assign(item, { confirm: 'N' });
      }
      operationParams.value = params;
      operationType.value = 'confirm';

      confirmSIPO(params).then((res) => {
        if (res.code === '0') {
          //
          const table = [
            {
              no: '1',
              programCode: programCode.value,
              programCategory: programCategory.value,
              approveQty: 0,
              rejectedQty: 0,
              pendingQty: 0,
              total: 0
            },
            {
              no: 'Total',
              programCode: '',
              programCategory: '',
              approveQty: 0,
              rejectedQty: 0,
              pendingQty: 0,
              total: 0
            }
          ];
          const data = res.data;
          let sum = 0;
          confirmTitle.value = 'SIPO Confirm';
          for (let i = 0; i < data.length; i++) {
            if (data[i].checkResult === 'Rejected') {
              table[0].rejectedQty = data[i].qty;
              table[1].rejectedQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Approved') {
              table[0].approveQty = data[i].qty;
              table[1].approveQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Pending') {
              table[0].pendingQty = data[i].qty;
              table[1].pendingQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            }
          }

          table[0].total = sum;
          table[1].total = sum;
          confirmDataSource.value = table;
          confirmVisible.value = true;
        } else {
          message.error(res.message);
        }
      });
    };

    const rejectPage = () => {
      let params = {};
      if (state.selectedRowKeys.length !== 0) {
        params = {
          ids: state.selectedRowKeys,
          offerTypeId: offerTypeId.value,
          programId: programId.value,
          confirm: 'N'
        };
      } else {
        const item = getParams(false);
        params = Object.assign(item, { confirm: 'N' });
      }
      operationParams.value = params;
      operationType.value = 'reject';
      rejectSIPO(params).then((res) => {
        if (res.code === '0') {
          //
          const table = [
            {
              no: '1',
              programCode: programCode.value,
              programCategory: programCategory.value,
              approveQty: '0',
              rejectedQty: '0',
              pendingQty: '0',
              total: 0
            },
            {
              no: 'Total',
              programCode: '',
              programCategory: '',
              approveQty: '0',
              rejectedQty: '0',
              pendingQty: '0',
              total: 0
            }
          ];
          confirmTitle.value = 'Reject to SICCO';
          const data = res.data;
          let sum = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].checkResult === 'Rejected') {
              table[0].rejectedQty = data[i].qty;
              table[1].rejectedQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Approved') {
              table[0].approveQty = data[i].qty;
              table[1].approveQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            } else if (data[i].checkResult === 'Pending') {
              table[0].pendingQty = data[i].qty;
              table[1].pendingQty = data[i].qty;
              sum = sum + data[i].qty * 1;
            }
          }
          table[0].total = sum;
          table[1].total = sum;
          confirmDataSource.value = table;
          confirmVisible.value = true;
        } else {
          message.error(res.message);
        }
      });
    };

    const communicationSuccess = () => {
      //? 校验: 1 必填校验
      if (!dealerCommunicationObj.deadline) {
        dealerCommunicationObj.isDeadlineEmpty = true;
        return;
      }
      //? 2 业务逻辑校验
      //? 沟通截止日期不得早于操作日期，否则报错：You cannot choose previous date
      const currentDate = new Date();
      if (Date.parse(dealerCommunicationObj.deadline) < Date.parse(currentDate.toDateString())) {
        message.error({
          content: 'You cannot choose previous date',
          duration: 2
        });
        return;
      }
      //? 若last communication是Y， round clsoing 不能为N，
      //? 否则报错：You cannot choose last communication if round is not closing
      if (dealerCommunicationObj.lastCommunication === 'Y' && dealerCommunicationObj.roundClosing === 'N') {
        message.error({
          content: 'You cannot choose last communication if round is not closing',
          duration: 2
        });
        return;
      }

      const params = Object.assign(
        {
          dealerCommunication: {
            comment: dealerCommunicationObj.commentValue,
            confirmDeadline: dealerCommunicationObj.deadline,
            lastCommunication: dealerCommunicationObj.lastCommunication,
            roundClosing: dealerCommunicationObj.roundClosing,
            programs: [
              {
                programCode: programCode.value,
                programCategoryId: offerTypeId.value,
                programId: programId.value
              }
            ]
          }
        },
        operationParams.value
      );

      checkSignCommunicationConfirmApi(params).then((res) => {
        if (res.code === '0') {
          // message.success(res.data.data);
          message.success('Request submitted successfully. Please query result in task center.');
          confirmVisible.value = false;
          dealerCommunicationObj.dealerCommunicationVisible = false;
          getTableDataByCondition();
          communicationClose();
        } else {
          message.error(res.message);
        }
      });
    };

    const exportPage = () => {
      if (state.selectedRowKeys.length !== 0) {
        const params = {
          vinNos: getVINNo(state.selectedRowKeys),
          offerTypeId: offerTypeId.value,
          programId: programId.value,
          programCode: programCode.value,
          programBu: bu.value,
          claimAppIdList: state.selectedRowKeys
        };
        exportPageApiSIPO(params);
      } else {
        exportPageApiSIPO(getParams(false));
      }
    };

    //显示详情页
    const showDetail = (item: TableType) => {
      const option = {
        dealerClaimId: item.id,
        programId: programId.value,
        offerTypeId: offerTypeId.value,
        vinNo: item.vinNo,
        id: item.id
      };
      deatilModal.value.showDetail(option);
    };
    //获取概览数量
    const getNum = () => {
      const params = {
        programId: programId.value,
        offerTypeId: offerTypeId.value
      };
      getGroupNum(params).then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].checkResult === 'Pending') {
            pending.value = res[i].qty;
          }
          if (res[i].checkResult === 'Rejected') {
            rejected.value = res[i].qty;
          }
          if (res[i].checkResult === 'Approved') {
            approved.value = res[i].qty;
          }
        }
      });
    };

    //获取ModelYear
    const getModelYear = () => {
      getModelYearApi({
        progDesignId: programId.value,
        offerTypeId: offerTypeId.value
      }).then((res) => {
        modelYearArray.value = res;
      });
    };
    //获取region数组
    const getRegion = () => {
      getRegionApi({ bu: bu.value }).then((res) => {
        regionArray.value = res;
      });
    };

    //获取claimType数组
    const getClaimType = async () => {
      claimTypeArray.value = await getClaimTypeApi();
    };

    onMounted(() => {
      getSourceDataList();
      getTableDataByCondition();
      getSelect();
      getNum();
      getModelYear();
      getRegion();
      getClaimType();
    });

    return {
      showDetail,
      sizeChange,
      pageChange,
      reset,
      checkingResultArray,
      checkingStatusArray,
      approved,
      rejected,
      pending,
      confirmVisible,
      ...toRefs(pagination),
      getSourceDataList,
      getTableByState,
      confirmTitle,
      confirmDataSource,
      confirmColumns,
      columns,
      dataSource,
      tableHeight,
      ...toRefs(state),
      onSelectChange,
      confirmClick,
      searchTable,
      input,
      fileList,
      hoverFileId,
      deleteSourceData,
      downloadSourceData,
      getTableDataByCondition,
      programCode,
      programName,
      programCategory,
      checkingPlan,
      textContentNo,
      batchPlaceholder,
      clearBatchQuery,
      ...toRefs(searchData),
      batchQueryEvent,
      ...toRefs(batchQuery),
      textNoArray,
      closeBatchQueryModal,
      textChangEvent,
      customRow,
      exportPage,
      deatilModal,
      rejectPage,
      confirmPage,
      modelYearArray,
      regionArray,
      claimTypeArray,
      setDealerCommuncationPage,
      communicationClose,
      ...toRefs(dealerCommunicationObj),
      communicationSuccess,
      holdingPeriodChange,
      moment
    };
  }
});
